const Base= "https://gestordeproyectos-api.azurewebsites.net";
// const Base= "http://localhost:3001";

export async function  GetAllUserStories() {
    try {
      const response = await fetch(Base+'/Userstory');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const jsonData = await response.json();
      return jsonData;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }
  export async function PostUserStory(userstory) {
    try {
      const response = await fetch(Base + '/Userstory/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userstory),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const jsonData = await response.json();
      return jsonData;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }