import axios from 'axios';
const Base= "https://gestordeproyectos-api.azurewebsites.net";

//  const Base= "http://localhost:3001";

export async function  PostSow(data) {
    try {
        const response = await fetch(Base+'/Proyecto/sow/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
        });
        if (!response.ok) {
        throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        return jsonData;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
 }

 export async function  GetSowById(id) {
    try {
        const response = await fetch(Base+'/Proyecto/sow/get/'+id, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
        });
        if (!response.ok) {
        throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        return jsonData;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
 }




//  export async function PostGenerarDocumento(id) {
//     const response = await axios({
//         url: `${Base}/Proyecto/sow/generate/${id}`,
//         method: 'POST',
//         responseType: 'blob', 
//         headers: {
//             'Accept': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
//         } 
//     });
    
//     const blob = await response.blob();
//     const downloadUrl = window.URL.createObjectURL(blob);
//     const a = document.createElement('a');
//     a.href = downloadUrl;
//     a.download = `SOW_${id}.docx`;
//     document.body.appendChild(a);
//     a.click();
//     a.remove();
//     window.URL.revokeObjectURL(downloadUrl);
// }
export async function PostGenerarDocumento(id) {
    try {
        console.log('Haciendo solicitud a:', `${Base}/Proyecto/sow/generate/${id}`);
        const response = await axios({
            url: `${Base}/Proyecto/sow/generate/${id}`,
            method: 'POST',
            responseType: 'blob',  // Indica que esperas un blob como respuesta
            headers: {
                'Accept': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            }
        });

        console.log('Respuesta recibida', response);
        const blob = response.data;  // Accede directamente al blob desde response.data
        console.log('Blob:', blob);
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = `SOW_${id}.docx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
        console.error('Error al generar el documento:', error);
    }
}
