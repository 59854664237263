const Base= "https://gestordeproyectos-api.azurewebsites.net";
// const Base= "http://localhost:3001";

export async function  GetAllSolicitudes() {
    try {
      const response = await fetch(Base+'/Solicitud/');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const jsonData = await response.json();
      return jsonData;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }

  export async function  GetSolicitudByid(id) {
    try {
      const response = await fetch(Base+'/Solicitud/'+id);
      if (!response.ok) {                  
        throw new Error('Network response was not ok');
      }
      const jsonData = await response.json();
      return jsonData;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }

  export async function  SendSolicitud(data) {
    try {
        const response = await fetch(Base+'/Solicitud/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
        });
        if (!response.ok) {
        throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        return jsonData;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
    }

    export async function  UpdateSolicitud(data) {
        try {
            const response = await fetch(Base+'/Solicitud/Aprobar/', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
            });
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            return jsonData;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
        }


      

        export async function  GetSolicitante(id) {
            try {
              const response = await fetch(Base+'/Solicitante/'+id);
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              const jsonData = await response.json();
              return jsonData;
            } catch (error) {
              console.error('Error fetching data:', error);
              throw error;
            }
          }


          export async function  GetJefeByCorreo (correo) {
            try {
              const response = await fetch(Base+'/Solicitante/jefe/'+correo);
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              const jsonData = await response.json();
              return jsonData;
            } catch (error) {
              console.error('Error fetching data:', error);
              throw error;
            }
          }