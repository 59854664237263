
const Base= "https://gestordeproyectos-api.azurewebsites.net";
// const Base= "http://localhost:3001";

export async function  GetAllPostMortem() {
    try {
      const response = await fetch(Base+'/Postmortem/');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const jsonData = await response.json();
      return jsonData;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }



export async function  PostPostMortem(data) {
    try {
        const response = await fetch(Base+'/Postmortem/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
        });
        if (!response.ok) {
        throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        return jsonData;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
    }

    export async function  UpdatePostMortem(data) {
      console.log(data);  
        try {

            const response = await fetch(Base+'/Postmortem/Actualizar/', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
            });
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            return jsonData;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
        }


