import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Proyectos from "./Pages/Proyectos";
import Solicitudes from "./Pages/Solicitudes";
import ResultadosEncuestas from "./Pages/ResultadosEncuestas";
import PostMortem from "./Pages/PostMortem";
import ContestarEncuesta from "./Pages/ContestarEncuesta";
import DetallesEncuesta from "./Pages/DetallesEncuesta";
import Error404 from "./Pages/NotFound";
import DetallesProyecto from "./Pages/DetallesProyecto";
import  DetallesSolicitudes from "./Pages/DetallesSolicitudes";
import RegistrarIdea from "./Pages/RegistrarIdea";
import Gracias from "./Pages/Gracias";
import { Login } from "./Pages/Login";
import UserStories from "./Components/UserStories";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import MatrizPrioridadPages from "./Pages/MatrizPrioridadPages";
import Sitios from "./Pages/Sitios";
import Historial from "./Pages/Historial";
const theme = createTheme({
Button: {

  color: 'white',
  backgroundColor: '#3f51b5',
  '&:hover': {
    backgroundColor: '#303f9f',
  },
},
});

const App = () => (
  <ThemeProvider theme={theme}>

  <Router>
    <Routes>
      <Route path="/" element={<Proyectos />} />
      <Route path="/solicitudes" element={<Solicitudes />} />
      <Route path="/resultados-encuestas" element={<ResultadosEncuestas />} />
      <Route path="/post-mortem/:id" element={<PostMortem />} />
      <Route path="/contestar-encuesta/:id/:solicitante" element={<ContestarEncuesta />} />
      <Route path="/detalles-proyecto/:id" element={<DetallesProyecto/>} />
      <Route path="/detalles-solicitud/:id" element={<DetallesSolicitudes />} />
      <Route path="/detalles-encuesta/:parametro" element={<DetallesEncuesta />} />
      <Route path="/registrar-idea" element={<RegistrarIdea />} />
      <Route path="/gracias" element={<Gracias />} />
      <Route path="/login" element={<Login/>} />
      <Route path="/matriz-prioridad" element={<MatrizPrioridadPages />} />
      <Route path="/Sitios-Web" element={<Sitios/>}/>
      <Route path="/historial/:id" element={<Historial />} />



  
   <Route path="/user-stories" element={<UserStories />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  </Router>   

    </ThemeProvider>
 
);

export default App;
