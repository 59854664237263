import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Typography, Paper, CircularProgress, Box } from '@mui/material';
import { ArrowCircleRight, ErrorOutline } from '@mui/icons-material'; // Importamos el ícono de error
import { GetSitios } from '../APIS/Sitios'; // Importamos la función que obtiene los datos
import { useNavigate } from 'react-router-dom'; // Importamos useNavigate para manejar la redirección
import MyDrawerComponent from '../Components/NavBar'; // Importamos el componente de navegación lateral

export function Sitios() {
  const [rows, setRows] = useState([]); // Estado para almacenar los sitios
  const [loading, setLoading] = useState(true); // Estado de carga
  const [error, setError] = useState(null); // Estado para manejar errores
  const navigate = useNavigate(); // Declaramos useNavigate para la navegación

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await GetSitios();
        setRows(data); // Asignamos los datos al estado
      } catch (error) {
        setError(error.message); // Si hay error, lo asignamos al estado
      } finally {
        setLoading(false); // Terminamos el estado de carga
      }
    }
    fetchData();
  }, []);

  // Indicador de carga más profesional con CircularProgress
  if (loading) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
        <CircularProgress size={60} style={{ color: '#0067A0', marginBottom: '20px' }} />
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>Cargando datos, por favor espere...</Typography>
      </Box>
    );
  }

  // Mensaje de error más profesional usando Paper y un ícono de error
  if (error) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
        <Paper elevation={3} style={{ padding: '20px', textAlign: 'center', maxWidth: '400px', backgroundColor: '#f8d7da' }}>
          <ErrorOutline style={{ color: '#d32f2f', fontSize: '40px' }} />
          <Typography variant="h6" style={{ color: '#d32f2f', fontWeight: 'bold', margin: '10px 0' }}>
            Ha ocurrido un error
          </Typography>
          <Typography style={{ color: '#d32f2f' }}>Error: {error}</Typography>
        </Paper>
      </Box>
    );
  }

  return (
    <div>
            <MyDrawerComponent pageName="Sitios" selectedMenuItem="Sitios" />

    <div style={{ padding: '20px' }}>
      {/* Agregamos la barra lateral de navegación */}

      {/* Contenedor sin bordes visibles ni líneas adicionales */}
      <TableContainer component={Paper} style={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', border: 'none' }}>
        <Table>
          <TableHead>
            <TableRow style={{ backgroundColor: '#0067A0' }}>
              <TableCell align="center" style={{ color: 'white', fontWeight: 'bold', padding: '10px' }}>Nombre</TableCell>
              <TableCell align="center" style={{ color: 'white', fontWeight: 'bold', padding: '10px' }}>URL</TableCell>
              <TableCell align="center" style={{ color: 'white', fontWeight: 'bold', padding: '10px' }}>Estatus</TableCell>
              <TableCell align="center" style={{ color: 'white', fontWeight: 'bold', padding: '10px' }}>Detalles</TableCell>
              <TableCell align="center" style={{ color: 'white', fontWeight: 'bold', padding: '10px' }}>Historial</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 ? (
              rows.map((item, index) => (
                <TableRow
                  key={item.idSitioWeb || index} // Asignamos una `key` única usando `item.idSitioWeb` o el índice como respaldo
                  style={{ backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ffffff', transition: 'background-color 0.3s' }}
                  hover={true}
                >
                  <TableCell align="center" style={{ maxWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', padding: '10px' }}>
                    {item.NombreWebSite}
                  </TableCell>
                  <TableCell align="center" style={{ maxWidth: 300, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', padding: '10px' }}>
                    <a href={item.url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#0067A0' }}>{item.url}</a>
                  </TableCell>
                  <TableCell 
                    align="center" 
                    style={{ 
                      maxWidth: 150, 
                      overflow: 'hidden', 
                      textOverflow: 'ellipsis', 
                      whiteSpace: 'nowrap', 
                      color: item.estado === 'Funcionando' ? 'green' : item.estado === 'Caido' ? 'red' : 'black', 
                      fontWeight: 'bold', 
                      padding: '10px' 
                    }}
                  >
                    {item.estado}
                  </TableCell>
                  <TableCell align="center" style={{ maxWidth: 300, whiteSpace: 'normal', wordWrap: 'break-word', padding: '10px' }}>
                    {item.detalles}
                  </TableCell>
                  <TableCell align="center" style={{ padding: '10px' }}>
                    {/* Navegamos a la página de historial con el ID del sitio */}
                    <IconButton onClick={() => navigate(`/historial/${item.idSitioWeb}`)} style={{ color: '#0067A0' }}> 
                      <ArrowCircleRight />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={5} style={{ padding: '20px' }}>No hay datos disponibles</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
    </div>
  );
}

export default Sitios;
