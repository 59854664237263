import React, { useState, useEffect } from 'react';
import { GetAllMatrizProyectos, CreateMatrizProyectos, deleteElement } from '../APIS/Matriz';
import { GetAllSolicitudes } from '../APIS/Solicitudes';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
  Typography,
  Box,
  Tooltip,
  Select,
  MenuItem,
  FormControl,
  Snackbar,
  Alert,
  Icon,
  CircularProgress
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SortIcon from '@mui/icons-material/Sort';
import SaveIcon from '@mui/icons-material/Save';

const initialFormState = {
  CodigoProyecto: '',
  ImpactoObjetivosEstrategicos: 0,
  HerramientasInternas: 0,
  EsInnovador: 0,
  Documentado: 0,
  RequiereInversion: 0,
  FrecuenciaUso: 0,
  ResuelvePuntoDolor: 0,
  DesarrolloInterno: 0,
  RevisionTecnica: '',
  ComentariosVarios: '',
  Total: 0
};

const MatrizPrioridad = () => {
  const [proyectos, setProyectos] = useState([]);
  const [filteredProyectos, setFilteredProyectos] = useState([]);
  const [solicitudesCombo, setSolicitudesCombo] = useState([]);
  const [sortOrder, setSortOrder] = useState('desc');
  const [filter, setFilter] = useState({ CodigoProyecto: '', Total: 'desc' });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const matrizData = await GetAllMatrizProyectos();
      let solicitudesData = await GetAllSolicitudes();
      
      // Filtra las solicitudes en revisión
      solicitudesData = solicitudesData.filter((solicitud) => solicitud.Status === 'En revisión');

      // Combina las ideas con las evaluaciones
      const combinedData = solicitudesData.map((solicitud) => {
        const existingProject = matrizData.find(proyecto => proyecto.CodigoProyecto === solicitud.Idea);
        return existingProject || {
          CodigoProyecto: solicitud.Idea,
          ImpactoObjetivosEstrategicos: 0,
          HerramientasInternas: 0,
          EsInnovador: 0,
          Documentado: 0,
          RequiereInversion: 0,
          FrecuenciaUso: 0,
          ResuelvePuntoDolor: 0,
          DesarrolloInterno: 0,
          RevisionTecnica: '',
          ComentariosVarios: '',
          Total: 0,
          id: null  // Si no tiene ID significa que aún no ha sido evaluado
        };
      });

      setProyectos(combinedData);
      setFilteredProyectos(combinedData);
      setSolicitudesCombo(solicitudesData);
      setLoading(false);  // Ocultar el indicador de carga
    };

    fetchData();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter((prev) => ({ ...prev, [name]: value }));
  };

  const applyFilterAndSort = () => {
    let updatedProyectos = [...proyectos];

    // Filtrar
    if (filter.CodigoProyecto) {
      updatedProyectos = updatedProyectos.filter((proyecto) =>
        proyecto.CodigoProyecto.toLowerCase().includes(filter.CodigoProyecto.toLowerCase())
      );
    }

    // Ordenar
    if (filter.Total) {
      updatedProyectos.sort((a, b) =>
        filter.Total === 'asc' ? a.Total - b.Total : b.Total - a.Total
      );
    }

    setFilteredProyectos(updatedProyectos);
  };

  useEffect(() => {
    applyFilterAndSort();
  }, [filter, proyectos]);

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    let newValue = name === 'CodigoProyecto' || name === 'RevisionTecnica' || name === 'ComentariosVarios' ? value : parseInt(value, 10);

    if (name !== 'CodigoProyecto' && name !== 'RevisionTecnica' && name !== 'ComentariosVarios') {
      if (newValue < 0) newValue = 0;
      if (newValue > 100) newValue = 100;
    }

    setFilteredProyectos((prevProyectos) => {
      const newProyectos = [...prevProyectos];
      newProyectos[index] = {
        ...newProyectos[index],
        [name]: newValue
      };
      return newProyectos;
    });
  };

  const calculateTotal = (proyecto) => {
    const {
      ImpactoObjetivosEstrategicos,
      HerramientasInternas,
      EsInnovador,
      Documentado,
      RequiereInversion,
      FrecuenciaUso,
      ResuelvePuntoDolor,
      DesarrolloInterno
    } = proyecto;

    return (
      (ImpactoObjetivosEstrategicos * 0.15) +
      (HerramientasInternas * 0.10) +
      (EsInnovador * 0.20) +
      (Documentado * 0.05) +
      (RequiereInversion * 0.10) +
      (FrecuenciaUso * 0.24) +
      (ResuelvePuntoDolor * 0.15) +
      (DesarrolloInterno * 0.01)
    );
  };

  const handleSave = async (index) => {
    const updatedProyecto = {
      ...filteredProyectos[index],
      Total: calculateTotal(filteredProyectos[index])
    };
    try {
      await CreateMatrizProyectos(updatedProyecto);
      setFilteredProyectos((prevProyectos) => {
        const newProyectos = [...prevProyectos];
        newProyectos[index] = updatedProyecto;
        return newProyectos;
      });
      setProyectos((prevProyectos) => {
        const newProyectos = [...prevProyectos];
        const originalIndex = prevProyectos.findIndex(proyecto => proyecto.id === updatedProyecto.id);
        if (originalIndex !== -1) {
          newProyectos[originalIndex] = updatedProyecto;
        }
        return newProyectos;
      });

      setSnackbarOpen(true);  // Mostrar mensaje de guardado en la nube
    } catch (error) {
      console.error('Error al guardar el proyecto:', error);
    }
  };

  const handleBlur = (index) => {
    handleSave(index);  // Guardar automáticamente cuando pierde el foco
  };

  const handleDelete = async (id, index) => {
    try {
      await deleteElement(id);
      setFilteredProyectos((prevProyectos) => prevProyectos.filter((_, i) => i !== index));
      setProyectos((prevProyectos) => prevProyectos.filter((proyecto) => proyecto.id !== id));
    } catch (error) {
      console.error('Error al eliminar el proyecto:', error);
    }
  };

  const handleSortChange = () => {
    const newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newOrder);
    setFilter((prevFilter) => ({ ...prevFilter, Total: newOrder }));
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ padding: 3, backgroundColor: '#f5f5f5', borderRadius: '8px', boxShadow: 3 }}>
      <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#1976d2' }}>
        Matriz de Prioridad de Proyectos
      </Typography>
      <Box sx={{ display: 'flex', gap: 1, marginBottom: 2, alignItems: 'center' }}>
        <TextField
          label="Filtrar por idea"
          name="CodigoProyecto"
          value={filter.CodigoProyecto}
          onChange={handleFilterChange}
          variant="outlined"
          size="small"
          sx={{ flexGrow: 1 }}
        />
        <FormControl sx={{ minWidth: 150 }}>
          <Select
            name="Total"
            value={filter.Total}
            onChange={handleFilterChange}
            variant="outlined"
            size="small"
            sx={{ borderRadius: '8px' }}
          >
            <MenuItem value="asc">Ascendente</MenuItem>
            <MenuItem value="desc">Descendente</MenuItem>
          </Select>
        </FormControl>
        <Tooltip title="Aplicar Orden">
          <IconButton size="small" onClick={handleSortChange}>
            <SortIcon />
          </IconButton>
        </Tooltip>
      </Box>
      
      {loading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper} sx={{ borderRadius: '8px', boxShadow: 3 }}>
          <Table size="small">
            <TableHead sx={{ backgroundColor: '#1976d2', color: 'white' }}>
              <TableRow>
                {[
                  { label: 'Idea', width: 200 },
                  'Total',
                  'Acciones',
                  '(15%) Impacto a objetivos Estratégicos',
                  '(10%) Herramientas internas',
                  '(20%) Innovador',
                  '(5%) Documentado',
                  '(10%) Inversión',
                  '(24%) Frecuencia de uso',
                  '(15%) Resuelve dolor',
                  '(1%) Desarrollo interno',
                  'Revisión Técnica',
                  'Comentarios',
                ].map((column, index) => (
                  <TableCell key={index} sx={{ color: 'white', fontWeight: 'bold' }}>
                    <Tooltip title={typeof column === 'string' ? column : column.label} arrow>
                      <span>{typeof column === 'string' ? column : column.label}</span>
                    </Tooltip>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredProyectos.map((proyecto, index) => (
                <TableRow key={index} sx={{ '&:hover': { backgroundColor: '#f0f0f0' } }}>
                  <TableCell>
                    <Tooltip title={proyecto.CodigoProyecto} arrow>
                      <span>{proyecto.CodigoProyecto}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>{calculateTotal(proyecto).toFixed(2)}</TableCell>
                  <TableCell>
                    <Tooltip title="Eliminar">
                      <IconButton size="small" onClick={() => handleDelete(proyecto.id, index)}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>

                  {['ImpactoObjetivosEstrategicos', 'HerramientasInternas', 'EsInnovador', 'Documentado', 'RequiereInversion', 'FrecuenciaUso', 'ResuelvePuntoDolor', 'DesarrolloInterno'].map((field) => (
                    <TableCell key={field}>
                      <TextField
                        name={field}
                        type="number"
                        value={proyecto[field]}
                        onChange={(e) => handleChange(e, index)}
                        onBlur={() => handleBlur(index)}  // Guarda automáticamente cuando pierde el foco
                        fullWidth
                        inputProps={{ min: 0, max: 100 }}
                        size="small"
                        sx={{ borderRadius: '8px' }}
                      />
                    </TableCell>
                  ))}
                  
                  <TableCell>
                    <TextField
                      name="RevisionTecnica"
                      value={proyecto.RevisionTecnica || ''}
                      onChange={(e) => handleChange(e, index)}
                      onBlur={() => handleBlur(index)}  // Guarda automáticamente cuando pierde el foco
                      fullWidth
                      size="small"
                      sx={{ borderRadius: '8px' }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      name="ComentariosVarios"
                      value={proyecto.ComentariosVarios || ''}
                      onChange={(e) => handleChange(e, index)}
                      onBlur={() => handleBlur(index)}  // Guarda automáticamente cuando pierde el foco
                      fullWidth
                      size="small"
                      sx={{ borderRadius: '8px' }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          Guardado automático.
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default MatrizPrioridad;
