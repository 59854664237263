import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, CircularProgress, Box, TablePagination } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material'; // Ícono para el mensaje de error
import { useParams } from 'react-router-dom'; // Para obtener el id desde la URL
import { GetHistorialRuta } from '../APIS/Historial'; // Importamos la función que obtiene los datos
import MyDrawerComponent from '../Components/NavBar'; // Barra de navegación lateral
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ComposedChart } from 'recharts';

const Historial = () => {
  const { id } = useParams();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [errorData, setErrorData] = useState([]);
  const [page, setPage] = useState(0); // Estado para manejar la página actual
  const [rowsPerPage] = useState(5); // Se fija el número de filas por página en 5

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await GetHistorialRuta(id);
        setRows(data);

        // Procesar los datos para la gráfica
        const groupedByDate = data.reduce((acc, currentItem) => {
          const date = currentItem.fecha.split(' ')[0]; // Extraemos solo la fecha
          acc[date] = (acc[date] || 0) + 1;
          return acc;
        }, {});

        let errorDataFormatted = Object.keys(groupedByDate).map(date => ({
          fecha: date,
          errores: groupedByDate[date]
        }));

        // Ordenar las fechas en orden cronológico
        errorDataFormatted = errorDataFormatted.sort((a, b) => new Date(a.fecha) - new Date(b.fecha));

        setErrorData(errorDataFormatted);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [id]);

  // Manejadores de cambio de página y número de filas por página
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Función para personalizar el texto de la paginación
  const labelDisplayedRows = ({ from, to, count, page }) => {
    return `Página ${page + 1} de ${Math.ceil(count / rowsPerPage)}`;
  };

  if (loading) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
        <CircularProgress size={60} style={{ color: '#0067A0', marginBottom: '20px' }} />
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>Cargando historial, por favor espere...</Typography>
      </Box>
    );
  }

  if (rows.length === 0) {
    return (
      <div>
        <MyDrawerComponent pageName="Historial" selectedMenuItem="Historial" />
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
          <Paper elevation={3} style={{ padding: '20px', textAlign: 'center', maxWidth: '400px', backgroundColor: '#f8d7da' }}>
            <ErrorOutline style={{ color: '#d32f2f', fontSize: '40px' }} />
            <Typography variant="h6" style={{ color: '#d32f2f', fontWeight: 'bold', margin: '10px 0' }}>
              Ha ocurrido un error
            </Typography>
            <Typography style={{ color: '#d32f2f' }}>No existe un historial a mostrar</Typography>
          </Paper>
        </Box>
      </div>
    );
  }

  return (
    <div>
      <MyDrawerComponent pageName="Historial" selectedMenuItem="Historial" />
      <div style={{ padding: '20px' }}>
        <TableContainer component={Paper} style={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', border: 'none' }}>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: '#0067A0' }}>
                <TableCell align="center" style={{ color: 'white', fontWeight: 'bold', padding: '10px' }}>ID Sitio</TableCell>
                <TableCell align="center" style={{ color: 'white', fontWeight: 'bold', padding: '10px' }}>Fecha y Hora</TableCell>
                <TableCell align="center" style={{ color: 'white', fontWeight: 'bold', padding: '10px' }}>Desarrollador Solucionó</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                <TableRow
                  key={item.idHistorial || index}
                  style={{ backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ffffff', transition: 'background-color 0.3s' }}
                  hover={true}
                >
                  <TableCell align="center" style={{ padding: '10px' }}>{item.id_sitioWeb}</TableCell>
                  <TableCell align="center" style={{ padding: '10px' }}>{item.fecha}</TableCell>
                  <TableCell align="center" style={{ padding: '10px' }}>{item.desarrolladorResolvió}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {/* Componente de paginación centrado */}
          <Box display="flex" justifyContent="center" alignItems="center">
            <TablePagination
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              labelDisplayedRows={labelDisplayedRows}
              style={{ textAlign: 'center' }} // Centramos la paginación
              rowsPerPageOptions={[]} // Eliminamos la opción para seleccionar las filas por página
            />
          </Box>
        </TableContainer>

        {/* Gráfico Combinado de Barras y Línea */}
        <Typography variant="h6" style={{ marginTop: '40px', fontWeight: 'bold', color: '#0067A0' }}>
          Gráfico de Errores por Fecha
        </Typography>
        <ResponsiveContainer width="100%" height={350}>
          <ComposedChart data={errorData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
            <defs>
              <linearGradient id="colorErrores" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#82ca9d" stopOpacity={0.8} />
                <stop offset="100%" stopColor="#82ca9d" stopOpacity={0.2} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="fecha" tick={{ fill: '#8884d8', fontSize: 12 }} />
            <YAxis allowDecimals={false} tick={{ fill: '#8884d8', fontSize: 12 }} />
            <Tooltip />
            <Bar dataKey="errores" barSize={30} fill="url(#colorErrores)" radius={[5, 5, 0, 0]}>
              <animate attributeName="height" from="0" to="100%" dur="0.8s" fill="freeze" />
            </Bar>
            <Line type="monotone" dataKey="errores" stroke="#ff7300" strokeWidth={3} dot={{ stroke: '#ff7300', strokeWidth: 2 }} activeDot={{ r: 8 }} />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default Historial;
