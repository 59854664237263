const Base= "https://gestordeproyectos-api.azurewebsites.net";
// const Base= "http://localhost:3001";
export async function NotificacionesByUser(correo) {
    try {
        console.log(Base + '/Notificaciones/Usuario/' + correo);
        const response = await fetch(Base + '/Notificaciones/Usuario/' + correo);
        
        if (!response.ok) {
            console.error(`Error: ${response.status} - ${response.statusText}`);
            throw new Error(`Network response was not ok: ${response.status}`);
        }

        const jsonData = await response.json();
        
        // Verificar si la respuesta es un error o no contiene notificaciones
        if (jsonData.error) {
            console.error(`Error de la API: ${jsonData.mensaje}`);
            return [];  // Devuelve un array vacío si no hay notificaciones
        }

        // Verifica si jsonData es un array antes de aplicar filter
        if (!Array.isArray(jsonData)) {
            console.error("Error: La respuesta no es un array.");
            throw new Error("La respuesta de la API no es un array.");
        }

        // Filtra solo las notificaciones que no se han visto
        const notificaciones = jsonData.filter((notificacion) => notificacion.Visto === "false");
        return notificaciones;
    } catch (error) {
        console.error('Error fetching data:', error.message);
        throw error;
    }
}




  
export async function PostNotificacion(notificacion) {
  try {
      const response = await fetch(Base + '/Notificaciones', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(notificacion),
      });
      
      // Verificar si la respuesta es exitosa
      if (!response.ok) {
          console.error(`Error: ${response.status} - ${response.statusText}`);
          throw new Error(`Network response was not ok: ${response.status}`);
      }

      const jsonData = await response.json();
      return jsonData;
  } catch (error) {
      // Captura y registra cualquier error durante la solicitud
      console.error('Error posting data:', error.message);
      throw error;
  }
}


export async function MarcarComoVisto(id) {
  try {
      const response = await fetch(Base + '/Notificaciones/Visto/' + id, {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
          },
      });
      
      // Verificar si la respuesta es exitosa
      if (!response.ok) {
          console.error(`Error: ${response.status} - ${response.statusText}`);
          throw new Error(`Network response was not ok: ${response.status}`);
      }

      const jsonData = await response.json();
      return jsonData;
  } catch (error) {
      // Captura y registra cualquier error durante la solicitud
      console.error('Error updating data:', error.message);
      throw error;
  }
}
