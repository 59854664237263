import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  TextField,
  Button,
  MenuItem,
  Select,
  Checkbox,
  Slider,
  Tooltip,
  IconButton,
  Tabs,
  Tab,
  Autocomplete
} from '@mui/material';
import MyDrawerComponent from '../Components/NavBar';
import { useParams } from 'react-router-dom';
import {
  GetByIdProyects,
  UpdateProyects,
  GetAllProyectos,
  SendTeamsAlertEncuesta,
  SendTeamsAlertProyecto
} from '../APIS/proyectos';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import { Visibility } from '@mui/icons-material';
import AvisoLogin from '../Components/AvisoLogin';
import { useAuth0 } from "@auth0/auth0-react";
import 'react-datepicker/dist/react-datepicker.css';
import SowForm from '../Components/SowComponent';
import UserStoriesPorProyecto from '../Components/UserStoriesPorProyecto';
import { GetAllSolicitudes } from '../APIS/Solicitudes';
import MatrizCambios from '../Components/MatrizCambios';
const DetallesProyecto = () => {
  const [data, setData] = useState(null);
  const [Nombre, setNombre] = useState(null);
  const [UN, setUN] = useState([]);
  const { id } = useParams();
  const [OpenModal, setOpenModal] = useState(false);
  const [error, setError] = useState(false);
  const [EstatusActual, setEstatus] = useState("");
  const [proyecto, setProyecto] = useState({
    Correo: "",
    IDSolicitud: 1,
    NombreProyecto: "",
    Codigo: "",
    Prioridad: "",
    Plataforma: "",
    Sponsor: "",
    Consultor: "",
    Avance: 0,
    FechaAtencion: "",
    FechaEstimada: "",
    FechaReal: "",
    FechaInicio: "",
    PM: "",
    Comentarios: "",
    UN: "",
    Costos: "",
    FrecuenciaCobro: "",
    DesgloceCostos: "",
    URLRepositorio: "",
    Actualización: false,
    Encuestado: false,
    PostMortem: false,
    Status: true,
    EstautusGarantia: "",
    FinGarantia: "",
    Descontinuado: "false",
    MotivoDescontinuacion: "",
    TiempoEstimado: "",
    BeneficiosEsperados: "",
    Desarrollador: "",
    horas_estimadas_desarrollo : "",
    areas_a_utilizar_desarrollo: "", // Inicializado como cadena vacía
  });
  const [Solicitud, setSolicitud] = useState(null);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toString() !== 'Invalid Date' ? date : new Date('1900-03-10');
  };

  useEffect(() => {
    const fetchDataFromApi = async () => {
      try {
        const jsonData = await GetByIdProyects(id);

        setNombre(jsonData.Codigo);
        setEstatus(jsonData.Status);

        let sponsorEmail = 'Unidad no encontrada';

        switch (jsonData.UN) {
          case "ADMINISTRACIÓN":
            sponsorEmail = "martha.vazquez@compucad.com.mx";
            break;
          case "ADOBE":
            sponsorEmail = "hugo.hernandez@compucad.com.mx";
            break;
          case "AUTODESK":
            sponsorEmail = "laura.paredes@compucad.com.mx";
            break;
          case "CDH":
            sponsorEmail = "felipe.ochoa@compucad.com.mx";
            break;
          case "COMPUCLOUD":
            sponsorEmail = "oswaldo.luna@compucloud.com.mx";
            break;
          case "DIRECCIÓN":
            sponsorEmail = "juanc.gonzalez@compucad.com.mx";
            break;
          case "GT":
            sponsorEmail = "gricelda.santana@compucad.com.mx";
            break;
          case "GOBIERNO":
            sponsorEmail = "jaime.rodriguez@compucad.com.mx";
            break;
          case "MKT":
            sponsorEmail = "raul.benhumer@compucad.com.mx";
            break;
          case "MICROSOFT":
            sponsorEmail = "juan.panas@compucad.com.mx";
            break;
          case "OPERACIONES":
            sponsorEmail = "mauricio.islas@compucad.com.mx";
            break;
          case "SAI":
            sponsorEmail = "fernando.jimenez@compucad.com.mx";
            break;
          case "SEGURIDAD Y MOVILIDAD":
            sponsorEmail = "fernando.jimenez@compucad.com.mx";
            break;
          case "SP":
            sponsorEmail = "uriel.martinez@compucad.com.mx";
            break;
          case "STAFF DIRECCIÓN":
            sponsorEmail = "juanc.gonzalez@compucad.com.mx";
            break;
          case "TRANSFORMACIÓN DIGITAL":
            sponsorEmail = "juan.panas@compucad.com.mx";
            break;
          case "INFRAESTRUCTURA":
            sponsorEmail = "raul.camara@compucad.com.mx";
            break;
          default:
            sponsorEmail = 'Unidad no encontrada';
            break;
        }

        const Solicitudes = await GetAllSolicitudes();
        const solicitud = Solicitudes.filter((item) => item.Idea === jsonData.NombreProyecto);
        const solicitudFiltrada = solicitud[0];
        setSolicitud(solicitudFiltrada);

        const BeneficiosEsperadosFinales = "Con impacto a nivel de : " + solicitudFiltrada?.Impacto + " y con los detalles: " + solicitudFiltrada?.Descripción;
        setProyecto({
          ...jsonData,
          Actualización: !!jsonData.Actualización,
          Encuestado: !!jsonData.Encuestado,
          PostMortem: !!jsonData.PostMortem,
          Sponsor: sponsorEmail,
          BeneficiosEsperados: BeneficiosEsperadosFinales,
          UN: solicitudFiltrada?.UN,
          areas_a_utilizar_desarrollo: jsonData.areas_a_utilizar_desarrollo ? jsonData.areas_a_utilizar_desarrollo.split(',') : [], // Convirtiendo cadena a matriz
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const FillUN = async () => {
      try {
        const jsonData = await GetAllProyectos();
        // Crear un array único de valores UN
        const uniqueUN = [...new Set(jsonData.map((item) => item.UN))];
    
        // Añadir manualmente la opción "Compucad"
        setUN([...uniqueUN, "Compucad"]);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    

    FillUN();
    fetchDataFromApi();
  }, [id]);

  const handleStatusChange = (newStatus) => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleConfirm = () => {
    setOpenModal(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProyecto({ ...proyecto, [name]: value });
  };

  const handleDateChange = (date, field) => {
    setProyecto({ ...proyecto, [field]: format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") });
  };

  const handleSliderChange = (event, value) => {
    setProyecto({ ...proyecto, Avance: value });
  };

  const handleAutocompleteChange = (event, newValue) => {
    setProyecto({ ...proyecto, areas_a_utilizar_desarrollo: newValue });
  };

  // const handleSubmit = (e) => {
  //   const proyectoToSubmit = {
  //     ...proyecto,
  //     areas_a_utilizar_desarrollo: proyecto.areas_a_utilizar_desarrollo.join(','), // Convert array to string
  //   };
  //   e.preventDefault();
  //   const SendAlertas = async () => {
  //     if (proyecto.Status === "En productivo" && proyecto.Status !== EstatusActual) {
  //       SendTeamsAlertProyecto(proyecto.Codigo, proyecto.NombreProyecto, proyecto.Desarrollador, proyecto.PM, Solicitud.solicitante.Correo, proyecto.Sponsor, proyecto.Status, "hola");
  //     }
  //     if (proyecto.Status === "Descontinuado" && proyecto.Status !== EstatusActual) {
  //       if (proyecto.MotivoDescontinuacion !== "") {
  //         SendTeamsAlertProyecto(proyecto.Codigo, proyecto.NombreProyecto, proyecto.Desarrollador, proyecto.PM, Solicitud.solicitante.Correo, proyecto.Sponsor, proyecto.Status, proyecto.MotivoDescontinuacion);
  //       } else {
  //         alert("El proyecto se actualizo pero no se envio la alerta, ya que no se ha llenado el campo de motivo de descontinuación");
  //         setError(true);
  //       }
  //     }
  //     if (proyecto.Status === "Pausado" && proyecto.Status !== EstatusActual) {
  //       SendTeamsAlertProyecto(proyecto.Codigo, proyecto.NombreProyecto, proyecto.Desarrollador, proyecto.PM, Solicitud.solicitante.Correo, proyecto.Sponsor, proyecto.Status, "hola");
  //     }
  //     if ((proyecto.Status === "En desarrollo" || proyecto.Status === "Desarrollo") && proyecto.Status !== EstatusActual) {
  //       if (proyecto.Desarrollador !== "" && proyecto.PM !== "" && proyecto.Sponsor !== "") {
  //         SendTeamsAlertProyecto(proyecto.Codigo, proyecto.NombreProyecto, proyecto.Desarrollador, proyecto.PM, Solicitud.solicitante.Correo, proyecto.Sponsor, proyecto.Status, "hola");
  //         setError(false);
  //       } else {
  //         alert("No puede pasar a desarrollo sin tener los campos de Desarrollador, PM y Sponsor llenos");
  //         setError(true);
  //       }
  //     }
  //   };

  //   const updateProyecto = async () => {
  //     console.log(proyecto);
  //     try {
  //       const jsonData = await UpdateProyects(proyecto);
  //       alert("Proyecto actualizado correctamente");
  //       window.location.href = "/detalles-proyecto/" + proyecto.Codigo;
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };

  //   if (proyecto.Status === "En desarrollo" || proyecto.Status === "Desarrollo") {
  //     if (proyecto.Desarrollador !== "" && proyecto.PM !== "" && proyecto.Sponsor !== "") {
  //       updateProyecto();
  //       SendAlertas();
  //     } else {
  //       alert("Completa los campos obligatorios");
  //     }
  //   } else {
  //     updateProyecto();
  //     SendAlertas();
  //   }
  // };
  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Convert areas_a_utilizar_desarrollo to a string
    const proyectoToSubmit = {
      ...proyecto,
      areas_a_utilizar_desarrollo: proyecto.areas_a_utilizar_desarrollo.join(','), // Convert array to string
    };
  
    const SendAlertas = async () => {
      if (proyecto.Status === "En productivo" && proyecto.Status !== EstatusActual) {
        SendTeamsAlertProyecto(proyecto.Codigo, proyecto.NombreProyecto, proyecto.Desarrollador, proyecto.PM, Solicitud.solicitante.Correo, proyecto.Sponsor, proyecto.Status, "hola", proyecto.Consultor, proyecto.Consultor);
      }
      if (proyecto.Status === "Descontinuado" && proyecto.Status !== EstatusActual) {
        if (proyecto.MotivoDescontinuacion !== "") {
          SendTeamsAlertProyecto(proyecto.Codigo, proyecto.NombreProyecto, proyecto.Desarrollador, proyecto.PM, Solicitud.solicitante.Correo, proyecto.Sponsor, proyecto.Status, proyecto.MotivoDescontinuacion, proyecto.Consultor);
        } else {
          alert("El proyecto se actualizo pero no se envio la alerta, ya que no se ha llenado el campo de motivo de descontinuación");
          setError(true);
        }
      }
      if (proyecto.Status === "Pausado" && proyecto.Status !== EstatusActual) {
        SendTeamsAlertProyecto(proyecto.Codigo, proyecto.NombreProyecto, proyecto.Desarrollador, proyecto.PM, Solicitud.solicitante.Correo, proyecto.Sponsor, proyecto.Status, "hola", proyecto.Consultor);
      }
      if ((proyecto.Status === "En planeación") && proyecto.Status !== EstatusActual) {
        if (proyecto.Desarrollador !== "" && proyecto.PM !== "" && proyecto.Sponsor !== "" && proyecto.Consultor !== "") {
          SendTeamsAlertProyecto(proyecto.Codigo, proyecto.NombreProyecto, proyecto.Desarrollador, proyecto.PM, Solicitud.solicitante.Correo, proyecto.Sponsor, proyecto.Status, "hola", proyecto.Consultor);
          setError(false);
        } else {
          alert("No puede pasar a desarrollo sin tener los campos de Desarrollador, PM y Sponsor llenos");
          setError(true);
        }
      }
    };
  
    const updateProyecto = async () => {
      try {
        const jsonData = await UpdateProyects(proyectoToSubmit); // Use proyectoToSubmit instead of proyecto
        alert("Proyecto actualizado correctamente");
        window.location.href = "/detalles-proyecto/" + proyecto.Codigo;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    if (proyecto.Status === "En planeación") {
      if (proyecto.Desarrollador !== "" && proyecto.PM !== "" && proyecto.Sponsor !== "") {
        updateProyecto();
        SendAlertas();
      } else {
        alert("Completa los campos obligatorios");
      }
    } else {
      updateProyecto();
      SendAlertas();
    }
  };
  
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const { isAuthenticated } = useAuth0();
  const desarrolladores = [
    'Desarrollador 1',
    'Desarrollador 2',
    'Desarrollador 3',
    'Desarrollador 4',
    'Desarrollador 5',
    'Desarrollador 6'
  ];

  return (
    <div>
      {isAuthenticated ? (
        <>
          <MyDrawerComponent pageName={"Proyecto: " + Nombre} selectedMenuItem="Solicitudes" />
          <Tabs value={tabValue} onChange={handleTabChange} centered>
            <Tab label="Generalidades" />
            <Tab label="Sow" />
            <Tab label="User storys" />
            <Tab label="Matriz de cambios"  style={ {marginLeft:'15px'}}/>
          </Tabs>
          {tabValue === 0 && (
            <div>
              <br />
              <div style={{ textAlign: 'center' }}>
                <p><strong>Solicitante:</strong> {Solicitud ? Solicitud.solicitante.Correo : 'N/A'}</p>
              </div>
              <br />
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3} justifyContent="center" alignItems="center" paddingLeft={10} paddingRight={10}>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      Nombre del Proyecto
                    </Typography>
                    <TextField
                      name="NombreProyecto"
                      value={proyecto.NombreProyecto}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      Código
                    </Typography>
                    <TextField
                      name="Codigo"
                      value={proyecto.Codigo}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      Desarrollador
                    </Typography>
                    <TextField
                      name="Desarrollador"
                      value={proyecto.Desarrollador}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      PM
                    </Typography>
                    <TextField
                      name="PM"
                      value={proyecto.PM}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      Sponsor
                    </Typography>
                    <TextField
                      name="Sponsor"
                      value={proyecto.Sponsor}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      Consultor de procesos
                    </Typography>
                    <TextField
                      name="Consultor"
                      value={proyecto.Consultor}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      Beneficios Esperados
                    </Typography>
                    <TextField
                      name="BeneficiosEsperados"
                      value={proyecto.BeneficiosEsperados}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      Estatus de Garantía
                    </Typography>
                    <Select
                      name="EstautusGarantia"
                      value={proyecto.EstautusGarantia}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                    >
                      <MenuItem style={{ width: '100%' }} value="Pendiente">Pendiente</MenuItem>
                      <MenuItem style={{ width: '100%' }} value="Activa">Activa</MenuItem>
                      <MenuItem style={{ width: '100%' }} value="Concluida">Concluida</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      Status
                    </Typography>
                    <Select
                      name="Status"
                      value={proyecto.Status}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                    >
                      <MenuItem style={{ width: '100%' }} value="Por iniciar">Por iniciar</MenuItem>
                      <MenuItem style={{ width: '100%' }} value="En planeación">En planeación</MenuItem>
                      <MenuItem style={{ width: '100%' }} value="En proceso">En proceso</MenuItem>
                      <MenuItem style={{ width: '100%' }} value="En desarrollo">En desarrollo</MenuItem>
                      <MenuItem style={{ width: '100%' }} value="Fase de pruebas">Fase de pruebas</MenuItem>

                      <MenuItem style={{ width: '100%' }} value="En productivo">En productivo</MenuItem>
                      <MenuItem style={{ width: '100%' }} value="Pausado">Pausado</MenuItem>
                      <MenuItem style={{ width: '100%' }} value="Descontinuado">Descontinuado</MenuItem>

                      {/* <MenuItem style={{ width: '100%' }} value="Por iniciar">Por iniciar</MenuItem> */}
                      {/* <MenuItem style={{ width: '100%' }} value="En revisión">En revisión</MenuItem> */}
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      Prioridad
                    </Typography>
                    <Select
                      name="Prioridad"
                      value={proyecto.Prioridad}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                    >
                      <MenuItem style={{ width: '100%' }} value="Regular">Regular</MenuItem>
                      <MenuItem style={{ width: '100%' }} value="Baja">Baja</MenuItem>
                      <MenuItem style={{ width: '100%' }} value="Critica">Critica</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      Plataforma
                    </Typography>
                    <Select
                      name="Plataforma"
                      value={proyecto.Plataforma}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                    >
                      <MenuItem style={{ width: '100%' }} value="Power Platform">Power Platform</MenuItem>
                      <MenuItem style={{ width: '100%' }} value="Lenguaje de programación">Lenguaje de programación</MenuItem>
                      <MenuItem style={{ width: '100%' }} value="Azure + Lenguaje de programación">Azure + Lenguaje de programación</MenuItem>
                      <MenuItem style={{ width: '100%' }} value="Licenciamiento basico">Licenciamiento basico</MenuItem>
                      <MenuItem style={{ width: '100%' }} value="Licenciamiento Básico Power Platform">Licenciamiento Básico Power Platform</MenuItem>
                      <MenuItem style={{ width: '100%' }} value="Basico + Conector premium SQL">Basico + Conector premium SQL</MenuItem>
                      <MenuItem style={{ width: '100%' }} value="Power Platform + Dynamics 365">Power Platform + Dynamics 365</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      UN
                    </Typography>
                    <Select
                      name="UN"
                      value={proyecto.UN}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                    >
                      {UN.map((item, index) => (
                        <MenuItem style={{ width: '100%' }} key={index} value={item}>{item}</MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      URL Repositorio
                    </Typography>
                    <TextField
                      name="URLRepositorio"
                      value={proyecto.URLRepositorio}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      Comentarios
                    </Typography>
                    <TextField
                      name="Comentarios"
                      value={proyecto.Comentarios}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={6}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      Motivo descontinuacion
                    </Typography>
                    <TextField
                      name="MotivoDescontinuacion"
                      value={proyecto.MotivoDescontinuacion}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={6}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      Horas estimadas de desarrollo
                    </Typography>
                    <Select
                      name="horas_estimadas_desarrollo"
                      value={proyecto.horas_estimadas_desarrollo}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                    >
                      <MenuItem style={{ width: '100%' }} value="Proyecto Chico 1-40 horas horas">Proyecto Chico 1-40 horas horas</MenuItem>
                      <MenuItem style={{ width: '100%' }} value="Proyecto Mediano 40-60 horas">Proyecto Mediano 40-60 horas</MenuItem>
                      <MenuItem style={{ width: '100%' }} value="Proyecto Grande 60-80 horas">Proyecto Grande 60-80 horas</MenuItem>
                      <MenuItem style={{ width: '100%' }} value="Actualización 1-20 horas">Actualización 1-20 horas</MenuItem>
                    </Select>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      Areas a utilizar el desarrollo
                    </Typography>
                    <Autocomplete
                      multiple
                      id="tags-outlined"
                      options={UN}
                      value={proyecto.areas_a_utilizar_desarrollo}
                      onChange={handleAutocompleteChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label=""
                          placeholder=""
                        />
                      )}
                    />
                  </Grid>

                  <Grid container spacing={8} justifyContent="center" alignItems="center" paddingLeft={3} paddingRight={3} paddingTop={5}>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        Avance
                      </Typography>
                      <Slider
                        value={proyecto.Avance}
                        onChange={handleSliderChange}
                        aria-labelledby="continuous-slider"
                        valueLabelDisplay="auto"
                        min={0}
                        max={100}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} lg={2}>
                      <Typography variant="subtitle1" gutterBottom>
                        Actualización
                      </Typography>
                      <Checkbox
                        checked={proyecto.Actualización}
                        onChange={(e) => setProyecto({ ...proyecto, Actualización: e.target.checked })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} lg={2}>
                      <Typography variant="subtitle1" gutterBottom>
                        Encuestado
                      </Typography>
                      <Checkbox
                        checked={proyecto.Encuestado}
                        readOnly
                      />
                      {proyecto.Encuestado && (
                        <Tooltip title="Ver detalles">
                          <IconButton onClick={() => {
                            window.location.href = `/detalles-encuesta/${proyecto.Codigo}`;
                          }}>
                            <Visibility />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} lg={2}>
                      <Typography variant="subtitle1" gutterBottom>
                        Post Mortem
                      </Typography>
                      <Checkbox
                        checked={proyecto.PostMortem}
                        readOnly
                      />
                      {proyecto.PostMortem && (
                        <Tooltip title="Ver detalles">
                          <IconButton onClick={() => {
                            window.location.href = `/post-mortem/${proyecto.Codigo}`;
                          }}>
                            <Visibility />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={12} justifyContent="left" alignItems="center" paddingRight={5} paddingTop={5} paddingLeft={2}>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        Desglose de costos
                      </Typography>
                      <TextField
                        name="DesgloceCostos"
                        value={proyecto.DesgloceCostos}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={6}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                      <Typography variant="subtitle1" gutterBottom>
                        Frecuencia de Cobro
                      </Typography>
                      <Select
                        name="FrecuenciaCobro"
                        value={proyecto.FrecuenciaCobro}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                      >
                        <MenuItem style={{ width: '100%' }} value="Mensual">Mensual</MenuItem>
                        <MenuItem style={{ width: '100%' }} value="Anual">Anual</MenuItem>
                        <MenuItem style={{ width: '100%' }} value="Semestral">Semestral</MenuItem>
                        <MenuItem style={{ width: '100%' }} value="Trimestral">Trimestral</MenuItem>
                      </Select>
                      <Typography variant="subtitle1" gutterBottom style={{ marginTop: 10 }}>
                        Costos en dolares (Solo número)
                      </Typography>
                      <TextField
                        name="Costos"
                        value={proyecto.Costos}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={6} justifyContent="center" alignItems="center" paddingLeft={5} paddingRight={5} paddingTop={5}>
                    <Grid item xs={12} sm={2} md={2} lg={2}>
                      <Typography variant="subtitle1" gutterBottom>
                        Fecha Atencion
                      </Typography>
                      <DatePicker
                        selected={proyecto.FechaAtencion}
                        onChange={(date) => handleDateChange(date, 'FechaAtencion')}
                        dateFormat="yyyy-MM-dd"
                        showTimeInput
                      />
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} lg={2}>
                      <Typography variant="subtitle1" gutterBottom>
                        Fecha de Inicio
                      </Typography>
                      <DatePicker
                        selected={proyecto.FechaInicio ? new Date(proyecto.FechaInicio) : null}
                        onChange={(date) => handleDateChange(date, 'FechaInicio')}
                        dateFormat="yyyy-MM-dd"
                        showTimeInput
                      />
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} lg={2}>
                      <Typography variant="subtitle1" gutterBottom>
                        Fecha Estimada
                      </Typography>
                      <DatePicker
                        selected={proyecto.FechaEstimada ? new Date(proyecto.FechaEstimada) : null}
                        onChange={(date) => handleDateChange(date, 'FechaEstimada')}
                        dateFormat="yyyy-MM-dd"
                        showTimeInput
                      />
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} lg={2}>
                      <Typography variant="subtitle1" gutterBottom>
                        Fecha Real
                      </Typography>
                      <DatePicker
                        selected={proyecto.FechaReal ? new Date(proyecto.FechaReal) : null}
                        onChange={(date) => handleDateChange(date, 'FechaReal')}
                        dateFormat="yyyy-MM-dd"
                        showTimeInput
                      />
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} lg={2}>
                      <Typography variant="subtitle1" gutterBottom>
                        Fin de Garantía
                      </Typography>
                      <DatePicker
                        selected={
                          proyecto.FinGarantia && !isNaN(new Date(proyecto.FinGarantia).getTime())
                            ? new Date(proyecto.FinGarantia)
                            : new Date(new Date().setDate(new Date().getDate() + 30))
                        }
                        onChange={(date) => handleDateChange(date, 'FinGarantia')}
                        dateFormat="yyyy-MM-dd"
                        showTimeInput
                      />
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} lg={2}>
                      <Typography variant="subtitle1" gutterBottom>
                        Tiempo estimado en semanas
                      </Typography>
                      <TextField
                        name="TiempoEstimado"
                        value={proyecto.TiempoEstimado}
                        onChange={handleChange}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <br />
                <br />
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{ color: '#fff', backgroundColor: '#1976d2', borderColor: '#1976d2' }}
                  >
                    Guardar
                  </Button>
                </div>
                <br />
                <br />
              </form>
            </div>
          )}
          {tabValue === 1 && (
            <div>
              <br />
              <SowForm />
            </div>
          )}
          {tabValue === 2 && (
            <div>
              <br />
              <UserStoriesPorProyecto idProyecto={id} />
          
            </div>
          )}
          {tabValue === 3 && (
            <div>
         <br />
              <MatrizCambios idProyecto={id} />

            </div>
          )}
        </>
      ) : (
        <AvisoLogin />
      )}
    </div>
  );
};

export default DetallesProyecto;
