
const Base= "https://gestordeproyectos-api.azurewebsites.net";
// const Base= "http://localhost:3001";

const LinkEncuestas="https://prod-141.westus.logic.azure.com:443/workflows/496d32f8d32340839644a96d802222c2/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=dm31e95dfArfU8Xdce1XMzULSSq8wEdrByjLaputvrw"
const LinkProyectos="https://prod-167.westus.logic.azure.com:443/workflows/73d5d0b12d794dcf868b27706c65ed75/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=EuVIqIdQj94Nr3-r9eDhOZHvdHVP9bfjTzarg4aDFiQ"
                    
export async function  GetAllProyectos() {
    try {
      const response = await fetch(Base+'/Proyecto');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const jsonData = await response.json();
      return jsonData;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }
  export async function GetByIdProyects(projectCode) {
    try {
      const response = await fetch(Base + '/Proyecto/CodigoProyecto/' + projectCode);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const jsonData = await response.json();
      return jsonData;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }
  
  export async function UpdateProyects(project) {
    try {
      const response = await fetch(Base + '/Proyecto/Actualizar/', {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(project),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const jsonData = await response.json();
      return jsonData;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }

export async function SendTeamsAlertEncuesta  (Codigo,Link,NombreProyecto, Desarrollador, Fecha, Correo, Recordatorio) {
    try {
      const response = await fetch(LinkEncuestas, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',

        },
        body: JSON.stringify(
          {
            "CodigoProyecto": Codigo,
            "Link": Link,
            "NombreProyecto":NombreProyecto,
            "Desarrollador": Desarrollador,
            "Fecha": Fecha,
            "Correo": Correo,
            "Recordatorio": Recordatorio
        }
        )
      }); 
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error('Error sending data:', error);
    }
  };
export async function SendTeamsAlertProyecto  (Codigo,NombreProyecto, Desarrollador, PM, Solicitante, Sponsor, Status, MotivoDesconti, Consultor) {
    try {
      const response = await fetch(LinkProyectos, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',

        },
        body: JSON.stringify(
          {
            "CodigoProyecto": Codigo,
            "NombreProyecto":NombreProyecto,
            "Desarrollador": Desarrollador,
            "PM": PM,
            "Solicitante": Solicitante,
            "Sponsor": Sponsor,
            "Status": Status,
            "MotivoDesconti":MotivoDesconti,
            "Consultor": Consultor
        }
        )
      }); 
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error('Error sending data:', error);
    }
  }


  